

import React, {useState, useEffect, useCallback, useMemo} from 'react';



const Gallery = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(null);

    const [selectedMedia, setSelectedMedia] = useState(null);
    const images = useMemo(() => ["/Plats/BuffetExterieur.jpg", "/Plats/BurgerEtFritesMaison.jpg", "/Plats/carpaccioStJacuesMangue.jpg",
     "/Plats/CarpaStjacquesSaumonFuméSceCitronée.jpg", "/Plats/Escargot.jpg", "/Plats/FoiegrasPoilé.jpg",
      "/Plats/MeliMeloMelonJambon.jpg", "/Plats/MilleFeuilledeSaumonFumée.jpg", "/Plats/OeufPocheSceRoquefort.jpg",
       "/Plats/SaumonFuméAnethAvocatMangue.jpg", "/Plats/StjacquesCurry.mp4", "/Plats/TartareSaumonPommes.jpg",
        "/Plats/TartareThonfraisEtagrumes.jpg", "/Plats/TartineGourmande.jpg", "/Plats/TimbaledeSaumonFumée.jpg",
         "/Plats/TournedosCanardMielSoja.jpg", "/Plats/BlinisSaumonFumé.jpg", "/Plats/PlancheCharcuterieFromage.jpg", "/Plats/AspergeOeuf.jpg"], []);



    const handleMediaClick = (event, imagePath) => {
        // Trouvez l'index de l'image qui a été cliquée
        const imageIndex = images.indexOf(imagePath);
      
        // Mettez à jour l'index de l'image actuelle
        setCurrentImageIndex(imageIndex);
      
        // Ouvrez la modale et affichez l'image
        setSelectedMedia(imagePath);
      };
      
      const handleCloseModal = useCallback(() => {
        setSelectedMedia(null);
        setCurrentImageIndex(null);
      }, []);
      
      const handlePrev = useCallback(() => {
        setCurrentImageIndex((prevImageIndex) => {
          const newImageIndex = prevImageIndex > 0 ? prevImageIndex - 1 : prevImageIndex;
          setSelectedMedia(images[newImageIndex]);
          return newImageIndex;
        });
      }, [images, setCurrentImageIndex]);
      
      const handleNext = useCallback(() => {
        setCurrentImageIndex((prevImageIndex) => {
          const newImageIndex = prevImageIndex < images.length - 1 ? prevImageIndex + 1 : prevImageIndex;
          setSelectedMedia(images[newImageIndex]);
          return newImageIndex;
        });
      }, [images, setCurrentImageIndex]);

      useEffect(() => {
        const handleKeyDown = (event) => {
          switch (event.key) {
            case 'ArrowLeft':
              handlePrev();
              break;
            case 'ArrowRight':
              handleNext();
              break;
            case 'Escape':
              handleCloseModal();
              break;
            default:
              break;
          }
        };
      
        if (selectedMedia) {
          window.addEventListener('keydown', handleKeyDown);
        }
      
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, [selectedMedia, handlePrev, handleNext, handleCloseModal]);

      

    return (
        <div className='Gallery'>
            <h2>Galerie de plats</h2>
            <p>Chaque plat est une œuvre d'art culinaire, préparée avec passion et expertise pour éveiller vos papilles. 
                Explorez notre galerie de photos et laissez-vous séduire par la présentation soignée et les ingrédients frais qui font de chaque bouchée une expérience inoubliable. 
                Que vous soyez un amateur de cuisine traditionnelle ou à la recherche de nouvelles découvertes gustatives,
                 notre restaurant est l'endroit idéal pour satisfaire vos envies et créer des souvenirs délicieux.
                <br />
                 <a href="/noustrouver">Cliquez ici pour nous trouver.</a>
                 </p>
            <div className="image-grid">
            <img src="/Plats/AspergeOeuf.jpg" alt="Intérieur du restaurant" onClick={(event) => handleMediaClick(event, "/Plats/AspergeOeuf.jpg")} />
            <img src="/Plats/BuffetExterieur.jpg" alt="Intérieur du restaurant" onClick={(event) => handleMediaClick(event, "/Plats/BuffetExterieur.jpg")} />
            <img src="/Plats/BurgerEtFritesMaison.jpg" alt= "Burger et frites maison" onClick={(event) => handleMediaClick(event, "/Plats/BurgerEtFritesMaison.jpg")} />
            <img src="/Plats/carpaccioStJacuesMangue.jpg" alt="Carpaccio de Saint-Jacques à la mangue" onClick={(event) => handleMediaClick(event, "/Plats/carpaccioStJacuesMangue.jpg")} />
            <img src="/Plats/CarpaStjacquesSaumonFuméSceCitronée.jpg" alt="Carpaccio de Saint-Jacques et saumon fumé, sauce citronnée" onClick={(event) => handleMediaClick(event,"/Plats/CarpaStjacquesSaumonFuméSceCitronée.jpg")} />
            <img src="/Plats/Escargot.jpg" alt="Escargot" onClick={(event) => handleMediaClick(event,"/Plats/Escargot.jpg")} />
            <img src="/Plats/FoiegrasPoilé.jpg" alt="Foie gras poêlé" onClick={(event) => handleMediaClick(event, "/Plats/FoiegrasPoilé.jpg")} />
            <img src="/Plats/MeliMeloMelonJambon.jpg" alt="Méli-mélo de melon et jambon" onClick={(event) => handleMediaClick(event, "/Plats/MeliMeloMelonJambon.jpg")} />
            <img src="/Plats/MilleFeuilledeSaumonFumée.jpg" alt="Mille-feuille de saumon fumé" onClick={(event) => handleMediaClick(event,"/Plats/MilleFeuilledeSaumonFumée.jpg")} />
            <img src="/Plats/OeufPocheSceRoquefort.jpg" alt="Oeuf poché, sauce roquefort" onClick={(event) => handleMediaClick(event, "/Plats/OeufPocheSceRoquefort.jpg")} />
            <img src="/Plats/SaumonFuméAnethAvocatMangue.jpg" alt="Saumon fumé, aneth, avocat, mangue" onClick={(event,) => handleMediaClick(event,"/Plats/SaumonFuméAnethAvocatMangue.jpg")} />
            <video src="/Plats/StjacquesCurry.mp4" controls alt="Saint-Jacques au curry" onClick={(event) => handleMediaClick(event, "/Plats/StjacquesCurry.mp4")}>
                Votre navigateur ne supporte pas la balise vidéo.
            </video>
            <img src="/Plats/TartareSaumonPommes.jpg" alt="Tartare de saumon et pommes" onClick={(event) => handleMediaClick(event,"/Plats/TartareSaumonPommes.jpg")} />
            <img src="/Plats/TartareThonfraisEtagrumes.jpg" alt="Tartare de thon frais et agrumes" onClick={(event) => handleMediaClick(event, "/Plats/TartareThonfraisEtagrumes.jpg")} />
            <img src="/Plats/TartineGourmande.jpg" alt="Tartine gourmande" onClick={(event) => handleMediaClick(event, "/Plats/TartineGourmande.jpg")} />
            <img src="/Plats/TimbaledeSaumonFumée.jpg" alt="Timbale de saumon fumé" onClick={(event) => handleMediaClick(event, "/Plats/TimbaledeSaumonFumée.jpg")} />
            <img src="/Plats/TournedosCanardMielSoja.jpg" alt="Tournedos de canard au miel et soja" onClick={(event) => handleMediaClick(event, "/Plats/TournedosCanardMielSoja.jpg")} />
            <img src="/Plats/BlinisSaumonFumé.jpg" alt="Blinis au saumon fumé" onClick={(event) => handleMediaClick(event, "/Plats/BlinisSaumonFumé.jpg")} />
            <img src="/Plats/PlancheCharcuterieFromage.jpg" alt="Planche de charcuterie et fromage" onClick={(event) => handleMediaClick(event, "/Plats/PlancheCharcuterieFromage.jpg")} />
            </div>
            {selectedMedia && (
      <div className="modal open" onClick={handleCloseModal}>
        <span className="close" onClick={handleCloseModal}>&times;</span>
        {selectedMedia.endsWith('.mp4') ? (
          <video src={selectedMedia} controls autoPlay loop>
            Votre navigateur ne supporte pas la balise vidéo.
          </video>
        ) : (
          <img src={selectedMedia} alt="Selected" />
        )}
        <div className='button-container'>
        <button className="prev" onClick={handlePrev}>Précédent</button>

        <button  className="next" onClick={handleNext}>Suivant</button>
        </div>
      </div>
    )}
  </div>
);
};

export default Gallery;