import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Where from "./pages/Where";
import Gallery from "./pages/Gallery";
import { Analytics } from '@vercel/analytics/react';



//router
function App() {
  return (

    <BrowserRouter>
    <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/noustrouver" element={<Where />} />
        <Route path="/galerie" element={<Gallery />} />
      </Routes>
      <Footer />
    </BrowserRouter>

  );
}

export default App;