import React from 'react';
import logo from "../assets/logophoto.webp"
const Where = () => {
    return (
        <div className='container'> 
            <h2>Nos coordonnées</h2>
            <p>1 Place de l'église, 78280 Guyancourt France</p>
            <p>E-mail: <a href="mailto:dinavillage@free.fr">dinavillage@free.fr</a></p>
            <p>Téléphone: +33 9 80 99 52 94</p>

            <h2>Horaires d'ouverture</h2>
            <p>Lundi - Vendredi: 12h00 - 14h00 & 19h00 - 21h00</p>
            

            <h2>Localisation</h2>
            <iframe
                width="600"
                height="450"
                loading="lazy"
                allowFullScreen
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2626.2870240727776!2d2.0710171159778136!3d48.77506277927722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6751067f16e0d%3A0x8853a79a1573ad57!2sPlace%20de%20l'%C3%A9glise%2C%2078280%20Guyancourt%2C%20France!5e0!3m2!1sen!2sus!4v1648499382851!5m2!1sen!2sus"
            ></iframe>

            <h2>Suivez-nous sur les réseaux sociaux</h2>
            <p>
                Facebook: <a href="https://www.facebook.com/people/Restaurant-Le-Village/100057474008438/">Le Village Facebook</a>
            </p>
            {/* <p>
                Instagram: <a href="https://www.instagram.com/VotreRestaurant">VotreRestaurant</a>
            </p> */}

            <h2>Photos</h2>
            <div>
            <img src="/InsideWebp.webp" alt="Intérieur du restaurant" />
            <img src="/OutsideWebp.webp" alt="Plats du restaurant" />
            </div>

            <h2>Contactez-nous</h2>
            <p>Pour toute question ou réservation, n'hesitez pas à nous contacter :</p>
            {/* Ajoutez ici votre formulaire de contact */}
        </div>
    );
};

export default Where;
