import React from 'react';
import { NavLink } from 'react-router-dom';

/**
 * footer component - call in router before the routes
 * @returns {JSX} - React component
 */
const Footer = () => {
    return (
        <div className='Footer'>

            <div className='navigation'>
                <NavLink to={"/"} className={(nav) => (nav.isActive ? 'nav-active' : 'nav')}>Home</NavLink>
                <NavLink to={"/noustrouver"} className={(nav) => (nav.isActive ? 'nav-active' : 'nav')}>Nous trouver</NavLink>
                <NavLink to={"/galerie"} className={(nav) => (nav.isActive ? 'nav-active' : 'nav')}>Nos Créations</NavLink>
            </div>

        </div>
    );
};

export default Footer;