import React from 'react';

/**
 * The Home page - call the Form component
 * @returns {JSX} - React Page
 */
const Home = () => {
    return (
        <div className='Home'>
            <div className='container'> 
                <div className='content'>
                    <h2>Bienvenue au Village - Restaurant Traditionnel à Guyancourt</h2>
                    <p>Nous sommes fiers de vous accueillir dans notre restaurant familial, le Village, situé à Guyancourt, dans le département des Yvelines, au cœur de Saint-Quentin-en-Yvelines. Ancré dans la tradition depuis plus de 10 ans, notre restaurant vous propose une expérience culinaire authentique et chaleureuse.</p>
                    <p>Notre cuisine est un mélange de saveurs Francaise, utilisant des ingrédients frais et de saison pour vous offrir des plats délicieux qui évoquent les souvenirs et les traditions de notre région.</p>
                    <p>Tous nos entrées, plats et desserts sont faits maison avec amour, afin de vous garantir une expérience gustative inoubliable.</p>
                    <h3>Photos du Restaurant</h3>
                    <div className="photo-gallery">
                        <img src="inside.jpg" alt="Intérieur du restaurant" />
                        <img src="Terrasse1.jpg" alt="Terrasse du restaurant" />
                        <img src="FrontOff.jpg" alt="Vue extérieure du restaurant" />
                    </div>
                    <p>Venez nous rendre visite et découvrez pourquoi notre restaurant est devenu une destination incontournable pour les amoureux de la bonne cuisine et de l'ambiance conviviale.</p>
                        <video width="320" height="240" controls>
                            <source src="Video2024.mp4" type="video/mp4" />
                             Votre navigateur ne supporte pas la vidéo.
                        </video>
                </div>
            </div>
            <div className='container'> 
                <div className='content'>
                    <h2>Rencontrez Notre Équipe</h2>
                    <p>Notre équipe est composée de professionnels passionnés qui mettent tout en œuvre pour vous offrir une expérience culinaire exceptionnelle. Découvrez les visages derrière les délicieux plats que vous dégustez au Village :</p>

                    <div className="team-members">
    <div>
        <img src="Dina en cuisine.jpg" alt="La patronne" />
        <p>Dina</p>
    </div>
    <div>
        <img src="G.jpg" alt="Chef cuisinier" />
        <p>Ghislain</p>
    </div>
</div>
                </div>
            </div>
        </div>
    );
};

export default Home;
