import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from "../assets/Logo1.png"

/**
 * Header component call in header
 * Add the navigation between page Home (create employee) and page Employee (view employees)
 * @returns {JSX} - React component
 */

const Header = () => {
    return (
        <header className='Header'>
            <NavLink to="/" >
                <img src={logo} className="logo" alt="Le Village" />
            </NavLink>
            <div className='navigation'>
                <NavLink to={"/"} className={(nav) => (nav.isActive ? 'nav-active' : 'nav')}>Home</NavLink>
                <NavLink to={"/noustrouver"} className={(nav) => (nav.isActive ? 'nav-active' : 'nav')}>Nous trouver</NavLink>
                <NavLink to={"/galerie"} className={(nav) => (nav.isActive ? 'nav-active' : 'nav')}>Nos Créations</NavLink>
            </div>

        </header>
    );
};

export default Header;